import * as React from 'react'

const TwitterEmbed = ( {handle, limit} ) => {
    let 
    href = "https://twitter.com/" + handle

    limit = limit || defaultProps.limit

    return (
        <div id={handle} className="mb-0 text-center">
            {/* <p className="mb-4 text-center"><a href={href} target='_blank' rel="noreferrer">@{user}</a></p> */}
            <a className="twitter-timeline" href={href} data-tweet-limit={limit} data-chrome='noheader nofooter' title='Opens in new tab'>@{handle}</a>
        </div>
    )
}

const defaultProps = {
    limit: 1,
}

export default TwitterEmbed