import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import TwitterEmbed from '../components/TwitterEmbed'

import {
  pagetitle,
  pagecontent
} from '../components/layout.module.css'

const About = ( {data} ) => {

  const {description,tech,title,twitter} = data.site.siteMetadata
  const {totalCount, nodes} = data.allMarkdownRemark
  const {date} = nodes[0].frontmatter,
  twitterLimit = 12,
  Title = 'About'

  return (
    <Layout>
      <Seo title={Title} keywords={tech.toString()} />
      <div className={pagecontent}>
        <header className='my-8'>
          <h1 className={pagetitle}>{title}</h1>
        </header>
        <div className="mx-auto max-w-full md:max-w-2xl mb-12">
          <div className="mb-12">
            <p className="mb-4 text-center">
            {`${description}`}
            </p>
            <ul className="list-disc ml-24">
              <li>{totalCount.toLocaleString()} articles</li>
              <li>from {date} till the present day</li>
            </ul>
          </div>
          <div className="mb-12">
            <h2 className={pagetitle}>Technology</h2>
            <p className="mb-4 text-center">
              This application has been created using the following technologies:
            </p>
            <ul className="list-disc ml-24">
              {
                tech.map((item, index) => (
                  <li key={index}>{item}</li>
                ))
              }
            </ul>
          </div>
          <div className="mb-20">
            <h2 className={pagetitle}>Contact</h2>
            <p className="mb-4 text-center">
              This application was created by <a href={`mailto:sjmitch20@outlook.com?subject=${title}`}>Stephen</a>
            </p>
          </div>
          {
            twitter && 
            <div className="mb-4">
              <h2 className={pagetitle}>Twitter</h2>
              <TwitterEmbed handle={twitter} limit={twitterLimit}></TwitterEmbed>
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
        twitter
        siteUrl
        tech
      }
    }
    allMarkdownRemark(limit: 1, sort: {order: ASC, fields: frontmatter___date}) {
      totalCount
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

export default About
